@font-face {
    font-family: 'RobotoBlack';
    src: url('Roboto-Black.woff2') format('woff2'),
        url('Roboto-Black.woff') format('woff');
    font-weight: 900;
}

@font-face {
    font-family: 'RobotoLight';
    src: url('Roboto-Light.woff2') format('woff2'),
        url('Roboto-Light.woff') format('woff');
    font-weight: 300;
}

@font-face {
    font-family: 'RobotoBold';
    src: url('Roboto-Bold.woff2') format('woff2'),
        url('Roboto-Bold.woff') format('woff');
    font-weight: bold;
}

@font-face {
    font-family: 'RobotoMedium';
    src: url('Roboto-Medium.woff2') format('woff2'),
        url('Roboto-Medium.woff') format('woff');
    font-weight: 500;
}

@font-face {
    font-family: 'RobotoThin';
    src: url('Roboto-Thin.woff2') format('woff2'),
        url('Roboto-Thin.woff') format('woff');
    font-weight: 100;
}

@font-face {
    font-family: 'RobotoRegular';
    src: url('Roboto-Regular.woff2') format('woff2'),
        url('Roboto-Regular.woff') format('woff');
    font-weight: normal;
}


@font-face {
    font-family: 'AvenirLight';
    src: url('Avenir-Light.ttf') format('woff2');
}