.app-uploader {
    background: #fff;
    border-radius: 4px;
    border: 1px dashed #D6D5DF;
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 6%), 0 1px 2px 0 rgb(0 0 0 / 4%);
    padding: 15px;
    text-align: center;
    position: relative;
    border-radius: 8px;
    transition: 0.5s;
}

.app-uploader:hover {
    background: #efefef;
    transition: 0.5s;
}

.app-block .app-uploader {
    border-radius: 4px;
    box-shadow: 0px 3px 6px #d6d5df29;
}

.app-uploader ul li {
    display: inline-block;
    border: 1px solid #D6D5DF;
    font-size: 11px;
    border-radius: 20px;
    padding: 2px 10px;
    line-height: 1;
    margin: 0 2px;
    color: #656565;
}

.ellipsis-text {
    width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ellipsis-text-content {
    width: 400px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ellipsis-text-moreWidth-content {
    width: 600px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.app-uploader input[type="file"] {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.app-uploader .upload-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-uploader .upload-content ul {
    margin: 2px 0;
}

.app-uploader .upload-content p {
    margin: 0;
    font-size: 18px;
    color: #242E42;
}

.app-uploader .upload-content p>span {
    color: #000ECF;
}

.app-uploader .upload-content span {
    color: #656565
}

.app-uploader .upload-content>div {
    /* padding-left: 40px; */
    width: 70%;
}

.app-uploader .upload-content em svg {
    fill: #656565;
}

.app-uploader-small p {
    font-size: 14px !important;
}

.app-block-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    min-height: 55px;
}

.app-block-title p {
    margin: 0;
}

.app-block-title .app-filters {
    margin: 0px;
}

.app-block-title .app-filters button {
    margin: 0 0 0 10px;
}

.recent-item-list li {
    padding: 10px 15px;
    height: 85px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
}

.recent-item-list li:hover {
    background: #eee;
}

.recent-item-list li:last-child {
    border: 0px;
}

.recent-item-list li .user-img {
    width: 75px;
    aspect-ratio: 1 / 1;
    display: inline-flex;
    background: #ededee;
    /* filter: contrast(129%); */
    border-radius: 4px;
    align-items: center;
    justify-content: center;
}

.recent-item-list li .user-img img {
    max-width: 100%;
    border-radius: 4px;
}

.recent-item-list li .recent-info {
    margin-left: 15px;

}

.recent-item-list .no-media .recent-info {
    margin: 0;

}

.recent-item-list .no-media {
    padding: 10px 0px;
}

.recent-item-list .no-media:hover {
    background: transparent;
}

.recent-item-list li .recent-info p {
    font-size: 16px;
    text-transform: capitalize;
    color: #656565;
    line-height: 1;
    margin: 5px 0 2px 0;
    width: 223px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    overflow-y: hidden;

}

.recent-item-list li .recent-info span {
    /* text-transform: capitalize; */
    color: #656565;
}

.recent-item-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.recent-item-icons .form-check {
    margin-right: 15px;
    display: flex;
    align-items: center;
}

.recent-item-icons em {
    margin-left: 10px;
}

.recent-item-icons em svg,
.recent-media-action em svg {
    width: 16px;
    fill: #656565;
    cursor: pointer;
    transition: all ease 0.2s;
}

.recent-item-icons .action-icons em svg {
    /* width: 23px !important; */
    width: 16px !important;
    fill: #7c7c7c !important;
    cursor: pointer !important;
    transition: all ease 0.2s !important;
}

.recent-media-action .action-icons em svg {
    width: 23px !important;
    fill: #7c7c7c !important;
    cursor: pointer !important;
    transition: all ease 0.2s !important;
}

.recent-item-icons em svg:hover {
    fill: #8a8a8a;
}

/* .recent-item-icons .ebook-icons em svg {
    width: 1.4em !important;
} */
.recent-chips span {
    display: inline-block;
    background: #eeeeef;
    padding: 0px 10px;
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
    border-radius: 5px;
}

.recent-media-block {
    border: 1px solid #C6C6C6;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #0000003d;
    margin-bottom: 15px;

}

.recent-media-action {
    height: 35px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.recent-media-user {
    width: 100%;
    height: 50px;
    background: #CED9E0;
    align-items: center;
    justify-content: space-between;
    display: inline-flex;
    padding: 0 15px;
}

.recent-media-img {
    width: 100%;
    height: 225px;
}

.recent-media-img img {
    width: 100%;
    height: 100%;
    border-radius: 5px 5px 0 0;
}

.custom-popover {
    width: 275px;
    background: #fff;
    box-shadow: 5px 5px 10px rgb(0 0 0 / 20%);
    position: absolute;
    border-radius: 3px;
    top: 40px;
    z-index: 1;
    padding: 20px;
}

.custom-popover .form-control {
    width: 100% !important;
    display: block !important;
}

.recent-item-icons .position-relative {
    display: flex;
    align-items: center;
}

.info-block {
    background: #fff;
    margin-bottom: 20px;
    border-radius: 8px;
    /* box-shadow: 5px 5px 10px rgb(0 0 0 / 20%); */
    box-shadow: 1px 3px 16px #878787 !important;
}

.info-block .info-heading {
    color: #fff;
    background: #010433;
    padding: 5px 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 15px;
    font-family: 'RobotoMedium';
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.info-block .info-content {
    padding: 15px 20px;
    color: #656565;
}

.info-block .info-content-card {
    padding: 5px;
    color: #656565;
}

.info-block .info-heading em svg {
    fill: #fff;
}

.info-block .info-content .form-control {
    height: 30px;
    font-size: 14px;
}

.share-list li {
    border-radius: 20px;
    text-align: center;
    margin-bottom: 10px;
    padding: 4px 10px 5px 10px;
    border: 1px solid #D6D5DF;
    box-shadow: 0px 0px 10px #d7dee365;
    font-size: 13px;
    color: #656565;
    cursor: pointer;
    transition: all ease 0.2s;
}

.share-list li:last-child {
    margin-bottom: 0px;
}

.share-list li:hover {
    border-color: #010433;
    background: #010433;
    color: #fff;
}

.input-icon {
    width: 100%;
    display: block;
}

.input-icon em {
    position: absolute;
    right: 10px;
    top: 4px;
}

.input-icon em svg {
    fill: #656565;
}

.input-icon input {
    padding-right: 40px;
}

.artwork-list {
    margin: 0px;
}

.artwork-list li {
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* height: 90px; */
}

.artwork-list li:last-child {
    border: 0px;
}

.artwork-list li .artwork-img {
    margin-right: 15px;
    /* filter: contrast(129%); */
}

.artwork-list li .artwork-img img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.artwork-img.square {
    /* width: 55px;
    height: 55px; */
    width: 75px;
    aspect-ratio: 1 / 1;
    margin-left: 2vw !important;
}

.artwork-img.wide {
    width: 159px;
    aspect-ratio: 16 / 9;
    /* min-width: 80px;
    min-height: 45px;
    max-width: 80px;
    max-height: 45px; */
}

.artwork-img.A4 {
    width: 99px;
    height: 153px;

}

.artwork-img.banner {
    width: 146px;
    /* height: 45px; */
    aspect-ratio: 2.77 / 1;
    /* min-width: 135px;
    min-height: 45px; */
}

.artwork-img.listDesign {
    width: 85px;
    height: 85px;
}

.slide-panel {
    width: 400px;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    /* z-index: 1; */
    z-index: 6;
    background: #fff;
    box-shadow: 0px 3px 6px #00000033;
}

.slide-panel .panel-header {
    /* height: 95px; */
    height: 100px;
    padding: 20px 20px 0px 20px;
}

.panel-header h4 {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #242E42;
    opacity: 1;
}

.slide-panel .panel-content {
    padding: 20px;
    height: calc(100% - 105px);
    overflow-y: auto;
}

.panel-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 6;
}

.slide-panel .custom-tabs {
    height: 28px;
}

.slide-panel .custom-tabs li a {
    font-size: 16px;
    text-decoration: none;
}

.image-list ul {
    display: flex;
    flex-wrap: wrap;
}

.image-list ul li {
    width: 50%;
    /* display: inline-flex; */
    margin-bottom: 8px;
    position: relative;
    overflow: hidden;
}

/* .image-list .art-banner li {
    height: 59px;
}

.image-list .art-wide li {
    height: 90px;
}

.image-list .art-square li {
    height: 170px;
} */

.image-list ul li:nth-child(odd) {
    padding-right: 4px;
}

.image-list ul li:nth-child(even) {
    padding-left: 4px;
}

.image-list ul li img {
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
    box-shadow: 0px 0px 20px #d7dee365;
}

.image-list ul li:hover .select-stock {
    bottom: 0px;
}

.pointer {
    cursor: pointer;
}

.link {
    cursor: pointer;
    color: #4e5071;
    text-decoration: underline;
}

.link:hover {
    color: #010433;
}

.app-password {
    display: flex;
    position: relative;
}

.app-password .password-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 10px;
}

.app-password .password-icon svg {
    fill: #9e9e9e;
    transition: all ease 0.2s;
}

.app-password .password-icon.view svg,
.app-password .password-icon:hover svg,
.app-copy .password-icon svg {
    fill: #666;
}

.app-password .form-control {
    padding-right: 35px;
}

.col-text {
    max-width: 65px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    float: left;
}

.image-uploader {
    width: 85px;
    height: 85px;
    border-radius: 100px;
    background: #fff;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    position: relative;
    overflow: hidden;
}

.image-uploader input[type="file"] {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    border-radius: 100px;
    cursor: pointer;
    z-index: 1;
}

.image-uploader .img-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all ease 0.2s;
    color: #fff;
}

.image-uploader:hover .img-overlay {
    opacity: 1;
}

.image-uploader img {
    max-width: 100%;
}

.icons-overlay:before {
    position: absolute;
    width: 100%;
    content: '';
    background: transparent;
    top: 0;
    right: 0;
    height: 100%;
    cursor: not-allowed;
    z-index: 1;
}

.icons-overlay em {
    opacity: 0.5;
}

.download-block {
    margin-bottom: 20px;
    border: 1px solid #D6D5DF;
    box-shadow: 0px 3px 6px #d6d5df29;
    border-radius: 4px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.download-block a {
    margin-right: 10px;
}

.sendToPdfFiller {
    display: none !important;
}

.btn-group .dropdown-menu {
    min-width: 140px;
    margin-top: 5px;
    border: 0px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
    padding: 0;
}

.btn-group .dropdown-menu li {
    /* padding: 8px 10px; */
    font-size: 14px;
    cursor: pointer;
}

.btn-group .dropdown-menu li:hover {
    background: #efefef;
}

.btn-group .dropdown-menu li a {
    display: block;
    text-decoration: none;
    color: #212529;
    padding: 8px 10px;
}

.toggle-dropdown {
    width: 20px;
    opacity: 0.5;
    transition: all ease 0.2s;
}

.toggle-dropdown:hover {
    opacity: 1;
}

.file-info {
    display: flex;
    align-items: center;
}

.file-info p {
    margin: 0;
    width: 450px;
    overflow-x: hidden;
}

.file-info .preview {
    width: 110px;
    height: 65px;
    background: #efefef;
    border-radius: 4px;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}


.delete-stock {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
}

.delete-stock ul li {
    width: 40px !important;
    display: block;
    padding: 8px 0 0 0;
    text-align: right;
}

.delete-stock ul li:hover ul {
    display: block;
}

.delete-stock ul li ul {
    top: 30px;
    right: 5px;
    position: absolute;
    width: 100px;
    display: none;
    background: #fff;
    color: #000;
    right: 8px;
    border-radius: 2px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
}

.delete-stock ul li ul li {
    width: 100% !important;
    text-align: left;
    cursor: pointer;
    padding: 5px 10px;
    color: #656565;
}

.delete-stock ul li ul li span {
    margin-left: 2px;
}

.delete-stock ul li ul li:hover {
    background: #efefef;
}

.toggle-icon {
    display: inline-block;
    width: 20px;
    transform: rotate(90deg);
}

.toggle-icon em svg {
    fill: #000;
}

.select-stock {
    position: absolute;
    width: calc(100% - 4px);
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: rgba(255, 255, 255, 0.8);
    left: 0;
    bottom: -40px;
    align-items: center;
    height: 40px;
    transition: all ease 0.2s;
}

.image-list ul li:nth-child(even) .select-stock {
    left: 4px;
}

.selected-art .select-stock {
    bottom: 0px;
}

.unpublished {
    opacity: 0.5;
    cursor: not-allowed;
}

.unpublished:hover {
    color: #656565 !important;
    background: #fff !important;
    border-color: #D6D5DF !important;
}

.app-filters-span {
    margin-bottom: 10px;
}

.folder-items {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 8px;
    /* box-shadow: 0 3px 10px 0 rgb(0 0 0 / 6%), 0 3px 10px 0 rgb(0 0 0 / 6%); */
    box-shadow: 1px 3px 16px #878787 !important;
    padding: 20px 25px;
}

.folder-items h3 {
    color: #242E42;
    font-size: 18px;
}

/* .folder-items-header{
    border-bottom: 1px solid #D6D5DF;
} */
.folder-items label {
    color: #656565;
}

.folder-items h2 {
    color: #656565;
    font-size: 18px;
}

.folder-items h6 {
    color: #656565;
    font-size: 16px;
}

.folder-items p {
    color: #656565;
    font-size: 14px;
}

.trending-list li {
    padding: 0 15px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.trending-list li:hover {
    background-color: #efefef;
}

.user-img {
    /* min-width: 55px;
    min-height: 55px; */
    width: 75px;
    aspect-ratio: 1 / 1;
    display: inline-block;
}

.user-img-wide {
    aspect-ratio: 16 / 9 !important;
    display: inline-block;
}

.user-banner {
    aspect-ratio: 3 / 1 !important;
    display: inline-block;
}

.trending-list li img {
    width: 100%;
    height: 100%;
    border-radius: 3px;
}

.main-content-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.text-content {
    margin-left: 15px;
    cursor: pointer;
}

.trending-list li h6 {
    font-size: 16px;
    /* text-transform: uppercase; */
    color: #656565;
    line-height: 1;
    margin: 5px 0 2px 0;
}

.trending-list li p {
    color: #656565;
    /* text-transform: capitalize; */
    font-size: 14px;
    font-family: 'RobotoRegular';
    line-height: 1.5;
}

.trending-list li:first-child {
    border-top: 1px solid #eee;
}

.trending-list li:last-child {
    margin-bottom: 0px;
}

.page-link {
    margin: 0 2px;
    border-radius: 5px;
}

.page-link-1 {
    background-color: #D6D5DF;
}

.page-link-2 {
    background-color: #010433;
    color: #fff;
}

.button {
    box-shadow: 0px 2px 20px #cfd9df66;
    border: 1px solid #696969;
    border-radius: 3px;
    background: #fff;
    padding: 6px 15px;
    margin-right: 10px;
    transition: all ease 0.2s;
    margin-bottom: 10px;
    color: #242E42;
}

.side-icons img {
    cursor: pointer;
}

.side-icons div img,
.side-icons input {
    cursor: pointer;
}

.side-content-div {
    width: 100%;
    border-bottom: 1px solid #D6D5DF;
}

.side-content-div:last-child {
    border: none;
}

.side-content-div:hover {
    cursor: pointer;
    background-color: #F2F3F4;
}

.border-active {
    border: 1px solid #010433 !important;
    border-radius: 5px;
    height: 100px;
}

.borders {
    border: 1px solid #D6D5DF;
    border-radius: 5px;
    height: 100px;
}

.borders:hover {
    cursor: pointer;
}

.borders:active {
    border: 3px solid #010433;
}

.borders h6,
.borders p,
.border-active h6,
.border-active p {
    color: #656565;
}

.developer-div {
    background-color: #fff;
    width: 300px;
    border-radius: 5px;
}

.dev-heading {
    color: #242E42;
}

.developer-heading {
    color: #242E42;
    font: bold;
}

.para-text {
    color: #656565;
}

.sub-app-block {
    width: 32%;
}

.heading-border {
    border-bottom: 1px solid #eee;
}

.now-logo-div {
    width: 130px;
    height: 80px;
    background-color: #f5f5f5;
}

.now-logo-div img {
    width: 90px;
    height: 14px;
}

.google-play-container {
    border: 1px solid #eee;
    border-radius: 5px;
}

.info-block .info-share {
    padding: 0 20px !important;
}

.paginator-top {
    border-top: 1px solid #eee;
    position: relative;
}

.frm-cntrl:disabled,
.frm-cntrl[readonly] {
    background-color: #ffffff !important;
}

@media screen and (max-width: 560px) {
    .lead-ad-forms-page-wrapper .custom-popover {
        left: -90px;
    }

    .image-info {
        flex-direction: column;
    }

    .ellipsis-text {
        width: 195px !important;
    }

    .file-info p {
        width: 140px !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .px-0 {
        padding-left: 0;
        padding-right: 0;
    }

    .dNoneOnMobile {
        display: none !important;
    }
}

@media screen and (max-width: 475px) {
    .lead-ad-forms-page-wrapper .custom-popover {
        left: -90px;
    }
}

@media screen and (max-width: 402px) {
    .lead-ad-forms-page-wrapper .custom-popover {
        left: -18px;
    }
}


@media screen and (max-width: 375px) {
    .lead-ad-forms-page-wrapper .custom-popover {
        left: 0;
    }
}

@media screen and (max-width:550px) {
    .custom-tabs li a {
        font-size: 20px !important;
    }
}

.list-heading {
    word-break: break-all !important;
}