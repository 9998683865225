@import 'assets/fonts/roboto.css';
@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import '../node_modules/primeng/resources/themes/fluent-light/theme.css';
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeicons/primeicons.css';
@import 'assets/css/global.css';
@import 'assets/css/common.css';
@import 'assets/css/modulesOverride.css';
@import 'assets/css/responsive.css';
@import 'assets/css/authenticate.css';
@import "~bootstrap/dist/css/bootstrap.css";
/* @import "~ngx-photo-editor/photo-editor.css"; */