@media screen and (min-width: 1600px) {
    .app-content-inner {
        width: 1200px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1600px){
.header-content {
    padding: 0 80px 0 75px;
    margin: 0 auto;
 }
}
@media screen and (min-width: 1700px){
    .header-content {
        padding: 0 140px 0 125px;
        margin: 0 auto;
     }
    }

@media screen and (min-width: 1600px){
/* .artwork-img.banner {
    width: 150px;
    height: 50px;
 }
 .artwork-img.wide{
    width: 120px;
    height: 67px;
 }
 .artwork-img.square {
    width: 60px;
    height: 60px;
 } */
 /* .stock-wide .defaultImg{
    height: 130px !important;
}
.stock-banner .defaultImg{
    height: 110px !important;
}
.stock-square .defaultImg{
    height: 176px !important;
} */
}
@media screen and (min-width: 1700px){
    /* .artwork-img.banner {
        width: 140px;
        height: 47px;
     }
     .artwork-img.wide{
        width: 100px;
        height: 55px;
     }
     .artwork-img.square {
        width: 60px;
        height: 60px;
     } */


 /* .stock-wide .defaultImg{
    height: 130px !important;
}
.stock-banner .defaultImg{
    height: 110px !important;
}
.stock-square .defaultImg{
    height: 176px !important;
} */
}

@media screen and (min-width: 1400px){
    .recent-media-img {
    height: 45vh;
}

@media screen and (min-width: 1600px){
.recent-media-img {
    height: 48vh !important;
 }
  /* li .user-img{
     width: 50px !important;
     height: 50px !important;
 } */
}
@media screen and (min-width: 1700px){
    .recent-media-img {
        height: 50vh !important;
     }
      /* li .user-img{
         width: 50px !important;
         height: 50px !important;
     } */
    }

@media screen and (max-width: 1365px) {
    .summary-list {
        padding-right: 0px !important;
    }
}

@media screen and (max-width: 1199px) {
    .summary-list {
        margin-top: 0px !important;
    }

    .summary-list li {
        align-items: flex-start !important;
    }

    .summary-list li p {
        flex-wrap: wrap;
    }

    .visible-md {
        display: block;
    }

    .hidden-md {
        display: none;
    }

    .align-center-md {
        align-items: center;
    }
    /* .stock-wide .defaultImg{
        height: auto !important;
    }
    .stock-banner .defaultImg{
        height: auto !important;
    }
    .stock-square .defaultImg{
        height: auto !important;
    } */

    .app-block{
        padding:10px 12px;
    }
    .app-content{
        height:calc(100% - 52px) !important;
        margin-top:52px !important;
        padding:15px 20px !important;
    }
    .header-page-wrapper header{
        padding:0 12px !important;
        height:52px !important;
    }
    .header-page-wrapper header h2{
        font-size:20px !important;
    }
    .header-page-wrapper header .logged-in-user{
        width:42px;
        height:42px;
        margin-left:12px;
    }
    .recent-media-img{
        height:182px;
    }
}

@media screen and (max-width: 991px) {
    .mt-md-10 {
        margin-top: 20px;
    }
    .app-content{
        padding:15px 10px !important;
    }
    .recent-media-img{
        height:156px;
    }
    .artwork-img.square{
        width:45px;
        height:45px;
    }
    .artwork-img.wide {
        width: 70px;
        height: 40px;
    }
    .artwork-img.banner {
        width: 90px;
        height: 37px;
    }
    .info-block .info-content{
        padding:10px 15px;
    }
    .btn-white{
        padding:4.5px ​10px;
    }
}

@media screen and (max-width: 767px) {
    .app-sidebar {
        left: -225px;
    }

    .app-container,
    .app-container header {
        width: 100% !important;
        margin-left: 0px !important;
    }

    header h2 {
        font-size: 30px !important;
    }

    .hamburger-icon,
    .sidebar-close {
        display: inline-block !important;
    }

    .app-logo {
        margin-top: 0px;
    }

    .summary-list {
        margin-top: 20px !important;
    }
}

/* tablet screens */
@media screen and (max-width: 576px) {
    .header-page-wrapper header{
        padding:0 12px !important;
        height:55px !important;
    }
    .header-page-wrapper header h2{
        font-size:20px !important;
    }
    .header-page-wrapper header .logged-in-user{
        width:42px;
        height:42px;
        margin-left:12px;
    }
    .header-page-wrapper header .btn-group > div{
        flex-direction: row-reverse;
    }
    .app-content{
        height: calc(100% - 52px) !important;
        margin-top: 52px !important;
    }
    .app-block.mt-20{
        margin-top:10px;
    }
    .app-search .form-control{
        /* padding:2px 2px 2px 32px !important; */
        /* background-position-x: 10px !important;
        background-position-y: 5px !important; */
        /* margin-left: 4% !important; */

        background-position-x: 7px !important;
        background-position-y: 8px !important;
        /* margin-left: 4% !important; */



    }
    .app-filters button{
        padding:3px 8px;
    }

    .p-paginator p-dropdown{
        right:0px !important;
    }
    .p-paginator .p-dropdown{
        left:0px;
    }

    .p-datatable .p-paginator-bottom{
        margin-top:6px;
        padding:5px 0px 18px;
    }
    .p-paginator{
        padding:20px 0px;
    }
    .file-info p{
        width: 100%;
    }
}

/* mobile screens */
@media screen and (max-width: 420px) {
    .header-page-wrapper header{
        padding:0 10px !important;
        height:45px !important;
    }
    .header-page-wrapper header h2{
        font-size:18px !important;
    }
    .header-page-wrapper header .logged-in-user{
        width:40px;
        height:40px;
        margin-left:10px;
    }
    .app-content{
        height: calc(100% - 45px) !important;
        margin-top: 45px !important;
        padding: 12px 10px !important;
        overflow-x:hidden;
        padding-bottom: 50px !important;
    }
    .recent-item-list li .user-img{
        width: 35px;
        height: 35px;
    }
    .recent-item-icons em{
        margin-left:5px;
    }
    .recent-item-icons .form-check{
        margin-right:0px;
    }
    .btn-checkbox{
        padding:6px 14px 6px 35px;
    }
    .app-block-title .info-title{
        font-size:14px !important;
    }
    .app-uploader{
        padding:5px 10px;
    }
    .sidebar-close{
        background:#ffffff;
    }
    .app-sidebar{
        z-index:9 !important;
    }
    .switch-bar{
        font-size:13px !important;
    }
    .recent-item-icons em svg, .recent-media-action em svg{
        width:11px;
    }
    .slide-panel{
        width:80%;
    }
}

@media screen and (max-width: 383px) {
  .p-paginator .p-paginator-pages .p-paginator-page, .p-paginator-element{
    width: 26px !important;
    height: 26px !important;
    font-size: 12px;
}
}

@media screen and (max-width: 360px) {
    .recent-item-list li .user-img{
        width: 30px;
        height: 30px;
    }
    .p-paginator .p-paginator-current{
        font-size:12px;

    }
    .p-paginator .p-paginator-pages .p-paginator-page, .p-paginator-element{
        width: 26px !important;
        height: 26px !important;
        font-size: 12px;
    }
    .app-content{
        padding:12px 5px !important;
    }
    .app-block-title{
        padding-bottom: 5px;
    }
    .btn-checkbox{
        padding:3px 9px 3px 29px;
    }
    .app-select-bar{
        padding:10px 5px 36px 0px;
    }
    .app-select-bar .selected-count{
        top:43px;
    }
    .app-filters button{
        margin-right:5px;
    }
    .recent-item-list li .recent-info p{
        max-width: 110px !important;
        font-size: 12px !important;
    }
    .app-filters button{
        padding:3px 8px !important;
    }
}
@media only screen and (max-width:689px)
{
  .embedes-outer
{
  justify-content: center;
}
}
@media only screen and (max-width:846px)
{
  .p-datatable .p-paginator-bottom {
    justify-content: center;
}
.p-paginator .p-paginator-current {
    position:unset;
}

.p-paginator p-dropdown {
    position:unset !important;
}
}
@media only screen and (max-width:560px){
  .p-dialog{
    width: 90%;
  }
}


@media screen and (max-width: 650px) {
    .menu-icon .hamburger-icon em svg{
        width: 24px !important;
    }
}
}

@media screen and (min-width:900px) and (max-width:1194px) {
    .app-content-inner{
        width: 900px !important;
        margin: 0 auto;
    }
}

@media screen and (min-width:1200px) and (max-width:1400px) {
    .app-content-inner{
        width: 995px !important;
        margin: 0 auto;
    }
}

@media screen and (min-width:1401px) and (max-width:1550px) {
    .app-content-inner{
        width: 1140px !important;
        margin: 0 auto;
    }
}