.main-div {
    background-color: #010433;
    height: 100%;
    width: 100%;
}

.sub-main {
    width: 100%;
    height: 100%;
    padding: 15px;
}

.nowcast-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 30px);
}

.nowcast-container-wrapp {
    width: 480px;
}

.logo {
    width: 100%;
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
}

.logo img {
    width: 90%;
}

.login-form {
    width: 100%;
    background: none;
    box-shadow: none;
    border-radius: 5px;
    opacity: 1;
    padding: 40px 20px !important;
}

.login-form label {
    width: 100%;
}

.login-email,
.login-password {
    text-align: left;
    letter-spacing: 0px;
    color: #656565;
    opacity: 1;
    padding: 0 0 20px 0;
}

.password {
    background: transparent url('/assets/images/authenticate/view.svg') no-repeat;
    opacity: 1;
    background-position: 97% center;
}

.button-div {
    padding: 10px 0;
    text-align: right;
}

.login-form a {
    text-decoration: none;
    color: #FFC210;
    outline: none;
}

.submit-btn {
    width: 100%;
    background-color: #010433;
    color: white;
    border-radius: 5px;
    padding: 5px 0;
    font-size: 17px;
    font-weight: bold;
    border: 0px;
}

.submit-btn:disabled {
    opacity: 0.7;
}

.submit-btn:hover {
    cursor: pointer;
}

.error {
    color: red;
    margin: 0px;
}

.login-form .button-div .submit-btn{
    background-color: none !important;
    border: 1px solid #fff !important;
    color: #fff !important;
    padding: 10px !important;
}

.login-form .form-control{
    padding: 10px !important;
}
.login-form label{
    color: #fff !important;
}

.login-form .password-icon{
    padding: 14px 10px !important;
}

@media screen and (max-width:767px) {
    .nowcast-container-wrapp {
        width: 100%;
    }
}