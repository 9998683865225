.p-component {
    font-family: 'RobotoRegular';
    font-size: 14px;
}

.p-datatable-wrapper {
    border: 1px solid #010433;
    border-radius: 5px;
}

.p-datatable .p-datatable-thead>tr>th:first-child {
    border-top-left-radius: 5px;
}

.p-datatable .p-datatable-thead>tr>th:last-child {
    border-top-right-radius: 5px;
}

.p-datatable .p-datatable-tbody>tr:last-child>td:first-child {
    border-bottom-left-radius: 5px;
}

.p-datatable .p-datatable-tbody>tr:last-child>td:last-child {
    border-bottom-right-radius: 5px;
}

.p-datatable .p-datatable-thead>tr>th .p-checkbox {
    align-items: flex-start;
}

.p-datatable .checkbox-col {
    width: 25px;
    padding-right: 0px !important;
}

.p-checkbox {
    align-items: center;
}

.p-checkbox .p-checkbox-box {
    width: 14px;
    height: 14px;
    border: 1px solid #707070;
    border-radius: 3px;
}

.p-datatable .p-datatable-thead>tr>th {
    font-size: 18px;
    color: #242E42;
    font-weight: normal;
    white-space: nowrap;


}

.p-datatable .p-datatable-tbody>tr>td {
    padding: 4px 0.75rem;
    font-size: 14px;
    color: #656565;

}

.p-datatable .p-datatable-tbody>tr>td .switch-input {
    margin-top: 1px;
}

.p-datatable .p-datatable-tbody>tr>td span em svg {
    fill: #929292;
    cursor: pointer;
    transition: all ease 0.2s;
}

.p-datatable .p-datatable-tbody>tr>td span em svg:hover {
    fill: #656565;
}

.p-datatable .p-datatable-tbody>tr>td span em {
    margin-right: 10px;
}

.p-datatable .p-paginator-bottom {
    border: 0px;
    justify-content: flex-start;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    margin-top: 20px;
    /* border: 2px solid rgb(37, 18, 18); */

}

.p-paginator .p-dropdown {
    width: 62px;
    height: 24px;
    border-color: #D5D5D5;
    border-radius: 3px;
    font-size: 14px;
    /* align-items: center; */

    /* left: -25px; */
    /* border: 2px solid green; */
}

.p-dropdown .p-dropdown-trigger {
    width: auto;
    padding: 0 8px 0 10px;
}

.p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
    font-size: 10px;
}

.p-paginator .p-paginator-current {
    order: 2;
    position: absolute;
    right: 0;
    font-size: 14px;
    color: #000000;
    width: auto;
    padding: 0;
    /* border: 2px solid rgb(0, 174, 255); */

}

.p-paginator p-dropdown {
    position: absolute;
    right: 190px !important;
    /* border: 2px solid red; */
    display: flex;
    justify-content: flex-end;
    width: auto;
    /* margin-left: 52%; */
}


.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 2px 10px;
}

.p-paginator .p-dropdown .p-dropdown-label {
    font-size: 14px;
    height: 100%;
    padding-top: 0px;
}

.p-paginator .p-paginator-pages .p-paginator-page,
.p-paginator-element {
    width: 30px !important;
    height: 30px !important;
    min-width: inherit !important;
    font-size: 14px;
    background: #fff;
    border-radius: 3px;
    margin: 2px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #010433;
    border-color: #010433;
    color: #fff;
}

.p-paginator-prev.p-paginator-element,
.p-paginator-first.p-paginator-element,
.p-paginator-next.p-paginator-element,
.p-paginator-last.p-paginator-element {
    background: #cccdd6;
    border-color: #cccdd6;
}

.p-paginator-element .p-paginator-icon {
    font-size: 12px;
}

.p-paginator-prev.p-paginator-element:hover,
.p-paginator-first.p-paginator-element:hover,
.p-paginator-next.p-paginator-element:hover,
.p-paginator-last.p-paginator-element:hover,
.p-paginator-prev.p-paginator-element:focus,
.p-paginator-first.p-paginator-element:focus,
.p-paginator-next.p-paginator-element:focus,
.p-paginator-last.p-paginator-element:focus {
    background: #fafbfd !important;
    border-color: #d3d8e9 !important;
    box-shadow: inset 0px 0px 0px 2px #d3d8e9;
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #010433;
    background: #010433;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    font-size: 8px;
}

.p-datatable .p-datatable-tbody>tr:hover,
.p-datatable .p-datatable-tbody>tr.p-highlight {
    background: #efefef;
}

.p-multiselect {
    width: 100%;
    height: 38px;
    box-shadow: 0px 3px 6px #d6d5df29;
    border: 1px solid #D6D5DF;
    border-radius: 0.25rem;
    font-size: 1rem;
}

.p-multiselect:hover {
    border-color: #D6D5DF !important;
}

.p-multiselect .p-multiselect-label {
    padding: 0.375rem 0.75rem;
}

.p-multiselect .p-multiselect-label.p-placeholder {
    color: #212529;
}

.p-multiselect-trigger-icon {
    font-size: 12px;
}

.p-multiselect-panel .p-multiselect-header,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    padding: 8px 10px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: none;
}

.p-multiselect-filter-container .p-inputtext {
    border-radius: 3px;
    padding: 5px 10px;
    box-shadow: 0px 3px 6px #d6d5df29;
    border-color: #D6D5DF;
}

.p-multiselect-filter-icon {
    font-size: 14px;
    margin-top: -7px;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 4px 10px;
    background: #eeeeef;
    color: #323130;
    border-radius: 3px;
    font-size: 14px;
    margin-right: 4px;
}

.p-multiselect .p-multiselect-trigger {
    width: 28px;
}

.p-calendar {
    width: 100%;
}

.p-calendar .p-datepicker {
    min-width: inherit !important;
}

.p-calendar-w-btn .p-inputtext,
.p-calendar-w-btn .p-inputtext:hover {
    border-color: #d6d5df;
    border-radius: 3px !important;
    box-shadow: 0px 3px 6px #d6d5df29;
}

.p-calendar-w-btn .p-datepicker-trigger,
.p-calendar-w-btn .p-datepicker-trigger:hover {
    background: transparent;
    border: 0px;
    position: absolute;
    right: 0;
    color: transparent;
    box-shadow: none;
    background: url('/assets/images/common/calendar.svg') 12px 12px no-repeat;
}

image-cropper {
    padding: 0px !important;
}

image-cropper>div {
    display: inherit;
}

image-cropper>div img {
    margin: 0 auto;
    border-radius: 4px;
}

.p-toast-message-icon {
    display: none;
}

.p-toast {
    width: 300px !important;
}

.p-toast-message {
    border-radius: 5px !important;
    padding: 5px 10px;
    border: 1px solid !important;
    border-top: 3px solid !important;
}

.p-toast-message-success {
    color: #588337 !important;
    background-color: #dcfbc5 !important;
    border-color: #588337 !important;
}

.p-toast-message-error .p-toast-icon-close {
    color: #96091C;
}

.p-toast-message-error {
    color: #96091C !important;
    background-color: #FFE6EA !important;
    border-color: #96091C !important;
}

.p-toast-message-error .p-toast-icon-close {
    color: #96091C;
}


.p-toast-message-info .p-toast-icon-close {
    color: #cc8925;
}

.p-toast-message-info {
    color: #cc8925 !important;
    background-color: #FFE6EA !important;
    border-color: #cc8925 !important;
}

.p-toast-message-info .p-toast-icon-close {
    color: #cc8925;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0px;
}

.p-dialog {
    padding: 15px 20px 12px 20px;
    background: #fff;
}

.p-dialog .p-dialog-header {
    padding: 0 0 5px 0;
    border-bottom: 1px solid #ccc;
}

.p-dialog .p-dialog-content {
    color: #656565;
    padding: 20px 0;
    min-height: 100px;
}

.createTabModal .p-dialog .p-dialog-content {
    overflow-y: inherit;
}

.createTabModal .p-dialog .p-dialog-content .main-modal {
    min-height: calc(100vh - 650px);
    overflow-y: auto;
    max-height: 650px;
}

.p-dialog .p-dialog-footer {
    padding: 12px 0 0 0;
    text-align: center;
    border-top: 1px solid #ccc;
}
.p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0 !important;
    width: auto !important;
}
.p-dialog .p-dialog-header .p-dialog-title {
    font-size: 18px;
    color: #656565;
    font-family: 'RobotoRegular';
    font-weight: inherit;
}

.p-dialog .p-dialog-header-icons {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background: transparent;
}

.p-dialog .p-dialog-footer button:last-child {
    margin-right: 0px;
}

.p-chips {
    display: block !important;
}

.p-chips ul,
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
    padding: 2px 12px !important;
    box-shadow: 0px 3px 6px #d6d5df29;
    border: 1px solid #D6D5DF;
    border-radius: 3px;
    color: #000;
    /* max-height: 124px; */
    /* overflow-y: auto; */
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
    word-break: break-word;
    display: -webkit-box;
    max-height: 80px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
    color: #000;
    font-family: 'RobotoRegular';
}

.p-chips .p-chips-multiple-container .p-chips-token {
    padding: 2px 10px;
    background: #eeeeef;
    border-radius: 3px;
    margin-right: 5px;
    font-family: 'RobotoRegular';
    margin: 1px;
}

.custom-paginator {
    display: block !important;
    background: transparent;
    padding: 0;
    margin-top: 10px;
    position: relative;
}

.custom-paginator .p-paginator-current {
    height: auto;
    top: 10px;
}

.custom-paginator p-dropdown {
    top: 5px;
}

.custom_paginator .p-paginator p-dropdown {
    position: absolute;
    right: 180px;
}

ngx-stripe-card .field.StripeElement.StripeElement--empty {
    padding: 180px 40px 40px 40px !important;
}

ngx-stripe-card .field.StripeElement {
    padding: 180px 40px 40px 40px !important;
}

.text-editor .p-editor-container .p-editor-toolbar.ql-snow,
.text-editor .p-editor-container .p-editor-content.ql-snow {
    border-radius: 5px;
    border: 1px solid #dbe4eb;
}

.login-stripe ngx-stripe-card .field.StripeElement.StripeElement--empty {
    border: 1px solid #dbe4eb !important;
    padding: 10px !important;
    border-radius: 5px !important;
    background-color: #fff;
}

.login-stripe ngx-stripe-card .field.StripeElement {
    border: 1px solid #696969 !important;
    padding: 10px !important;
    border-radius: 5px !important;
    background-color: #fff;
}

.embed-dialog .p-dialog-mask {
    left: -195px !important;
}

.embed-dialog .p-dialog-content {
    overflow: hidden;
    padding-bottom: 0;
}

.embed-dialog .p-dialog {
    padding-bottom: 0 !important;
}

.most-recent .p-dialog {
    padding-bottom: 20px !important;
}


.platform-carousal-wrapper p-carousel .p-carousel-indicators {
    display: none;
}

.platform-carousal-wrapper p-carousel .p-carousel-container button {
    display: none;
}

.activeArrow p-carousel .p-carousel-content {
    position: relative;
    overflow: hidden;
}

.activeArrow p-carousel .p-carousel-indicators {
    display: inline-flex;
    position: absolute;
    top: 70px;
    width: 100%;
    align-items: center;
    padding: 0;
}

.activeArrow .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #fff;
    width: 5px;
    height: 6px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 10px;
}

.activeArrow .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: #0078d4;
}

.activeArrow p-carousel .p-carousel-container button {
    display: block !important;
}

.activeArrow .p-carousel .p-carousel-content .p-carousel-prev {
    position: absolute;
    width: 10px !important;
    height: 10px !important;
    padding: 30px 0;
    margin-bottom: 25px;
    left: 0;
    z-index: 99;
    color: #fff;
}

.activeArrow .p-carousel .p-carousel-content .p-carousel-prev:hover {
    color: #fff !important;
    border-color: transparent !important;
    background: transparent !important;
}

.activeArrow .p-carousel .p-carousel-content .p-carousel-next {
    position: absolute;
    width: 10px !important;
    height: 10px !important;
    padding: 30px 15px;
    margin-bottom: 25px;
    right: 0;
    z-index: 99;
    color: #fff;
}


.activeArrow .p-carousel .p-carousel-content .p-carousel-next:hover {
    color: #fff !important;
    border-color: transparent !important;
    background: transparent !important;
}


.activeArrow .p-carousel .p-carousel-content .p-carousel-next:focus {
    outline: 0 !important;
    box-shadow: inset 0 0 0 1px transparent !important;
}

.activeArrow .p-carousel .p-carousel-content .p-carousel-prev:focus {
    outline: 0 !important;
    box-shadow: inset 0 0 0 1px transparent !important;
}

/* p-dialog.templatePreview .p-dialog .p-dialog-header-icons{
    display: none !important;
} */
.custom-dialog .p-dialog-header-icons {
    right: 4px;
    top: 12px;
}

.p-dial .p-dialog-header .p-dialog-title {
    padding: 10px;
}

::ng-deep p-calendar input {
    border: 1px solid #ccc !important;
    border-radius: 3px !important;
}

::ng-deep p-calendar .p-inputtext:enabled:hover {
    border: 1px solid #ccc !important;
}

.p-dialog {
    border-radius: 15px;
}

.p-dialog-content {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}

.p-dialog .p-dialog-header {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}

.p-dialog .p-dialog-footer {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
}

.timepicker-dial__hint {
    display: none !important;
}

.timepicker__header {
    background-color: #010433 !important;
    padding: 10px !important;
    border-top-left-radius: 13px !important;
    border-top-right-radius: 13px !important;
}

.timepicker {
    width: 285px !important;
    border-radius: 13px !important;
}

.timepicker .clock-face{
    width: 200px !important;
    height: 200px !important;
    padding: 5px !important;
}

.timepicker .clock-face .clock-face__number{
    transform-origin: 0 75% !important;
}

.clock-face__number>span.active {
    background-color: #010433 !important;
}

.clock-face__clock-hand {
    background-color: #010433 !important;
    height: 95px !important;
    top: calc(50% - 95px) !important;
}

.timepicker-button {
    color: #010433 !important;
}

.timepicker__actions {
    padding-top: 0 !important;
    border-bottom-left-radius: 13px !important;
    border-bottom-right-radius: 13px !important;
}

.timepicker-dial__container {
    justify-content: center !important;
}

/* .timepicker-dial__item ::-ms-input-placeholder {
    color: red !important;
}

.timepicker-dial__item_active ::-ms-input-placeholder {
    color: red !important;
} */

@media screen and (max-width: 560px) {
    p-dialog .p-dialog {
        width: 87vw !important;
    }

    .activeArrow p-carousel .p-carousel-indicators {
        display: inline-flex;
        position: absolute;
        top: 120px;
        width: 100%;
        align-items: center;
        padding: 0;
    }

    .activeArrow .p-carousel .p-carousel-indicators .p-carousel-indicator button {
        background-color: #fff;
        width: 7px;
        height: 7px;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 10px;
    }



}

@media screen and (max-width: 475px) {
    p-dialog .p-dialog {
        width: 87vw !important;
    }

    .activeArrow p-carousel .p-carousel-indicators {
        display: inline-flex;
        position: absolute;
        top: 105px;
        width: 100%;
        align-items: center;
        padding: 0;
    }

    .activeArrow .p-carousel .p-carousel-indicators .p-carousel-indicator button {
        background-color: #fff;
        width: 7px;
        height: 7px;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 10px;
    }
}

@media screen and (max-width: 375px) {
    p-dialog .p-dialog {
        width: 87vw !important;
    }

    .activeArrow p-carousel .p-carousel-indicators {
        display: inline-flex;
        position: absolute;
        top: 87px;
        width: 100%;
        align-items: center;
        padding: 0;
    }

    .activeArrow .p-carousel .p-carousel-indicators .p-carousel-indicator button {
        background-color: #fff;
        width: 7px;
        height: 7px;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 10px;
    }
}


.collapse:not(.show) {
    display: none;

}

.collapsing {
    height: 0;
    overflow: hidden;
    @include transition($transition-collapse);
}

.collapsing .collapse-horizontal {
    width: 0;
    height: auto;
    @include transition($transition-collapse-width);

}

ngx-material-timepicker-container .timepicker-overlay {
    z-index: 99999 !important;
}


mat-sidenav:not(.mat-drawer-opened) {
    transform: translate3d(0, 0, 0) !important;
    visibility: visible !important;
    width: 60px !important;
    overflow: hidden;
}

mat-sidenav.mat-width {
    width: 50px !important;
}

mat-sidenav:not(.mat-drawer-opened) div.leftNav div.navProfile img {
    width: 40px;
    margin: 16px 0 0px 0;
}

mat-sidenav:not(.mat-drawer-opened) .navTitle,
mat-sidenav:not(.mat-drawer-opened) .profileTitle {
    display: none;
}


mat-datepicker-toggle .mat-button-wrapper svg{
    fill: grey !important;
  }

  .cdk-overlay-pane{
      z-index: 1005 !important;
  }

p-dialog .p-dialog-mask{
    z-index: 999 !important;
}
.mat-datepicker-input.form-control[readonly] {
    background-color: #fff !important;
}

.mat-calendar-arrow {
    border-top-color: #000 !important;
}
.mat-datepicker-content {
    background-color: #ffffff !important;
    color: #000 !important;
}

.mat-datepicker-toggle, .mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button {
    color: #000 !important;
}
.mat-calendar-body-label {
    color: #000000 !important;
}

.mat-calendar-content thead,.mat-calendar-content tbody,.mat-calendar-content tfoot,.mat-calendar-content tr,.mat-calendar-content td,.mat-calendar-content th{
    color: #000 !important;
}

.mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: rgb(0 0 0 / 50%) !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: #000000 !important;
    color: #000 !important;
}
.mat-calendar-content td:hover{
    /* background-color: #91b8ffc2 !important;
    border-radius: 50px; */
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    background-color: #91b8ffc2 !important;
}
.mat-calendar-body-cell-content, .mat-date-range-input-separator {
    color: #000 !important;
    border-color: transparent;
}
.mat-calendar-body-selected {
    background-color: #3363cf !important;
    color: #fff !important;
}
.positionBottom::ng-deep .p-calendar .p-datepicker{
    top: 40px !important;
  }
   .newPtable .p-datatable-wrapper {
      border: none !important;
      border-radius: 10px !important;
  }

  p-table .newPtable thead,
  tbody {
      border-bottom: 1px solid #eee;
      padding-bottom: 10px;
      height: 55px;
      background: #ffffff;
  }

  p-table .newPtable thead {
      font-size: 16px !important;
      color: #242E42 !important;
      text-transform: uppercase;
      font-weight: 800;
  }

  p-table .newPtable thead td {
      padding: 22px !important;
  }

  p-table .newPtable tbody tr {
      height: 85px;
  }

  .newPtable .p-paginator-bottom {
      margin-top: 0px !important;
      padding: 15px;
  }
 p-table .newPtable {
        box-shadow: 1px 3px 16px #878787 !important;
            border-radius: 10px !important;
  }
  p-table .newPtable .p-paginator .p-paginator-current{
    right: 22px !important;
  }
  
  .p-table-table p-table .p-datatable .p-datatable-thead>tr{
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    min-height: 55px;
}
.p-table-table p-table .p-datatable .p-datatable-tbody>tr>td{
    text-align: center;
}
.p-table-table p-table .p-datatable .p-datatable-thead>tr>th{
    font-size: 16px !important;
    color: #656565 !important;
    font-weight: 800 !important;
    text-align: center;
  }
  .p-table-table p-table .p-datatable-wrapper{
    border: none !important;
  }
  .p-table-table p-table .p-datatable .p-datatable-tbody > tr{
    height: 85px;
    border-bottom: 1px solid #eee;
  }
  .p-table-table .p-datatable table{
    table-layout: inherit !important;
  }
  @media screen and (min-width:1800px) and (max-width:2800px){
    .createTabModal .p-dialog .p-dialog-content .main-modal {
        min-height: calc(100vh - 1000px);
        overflow-y: auto;
        max-height: 1000px;
    }
}