body {
    background: #f7f8fa;
    font-size: 14px;
    font-family: 'RobotoRegular' !important;
    letter-spacing: 0.5px !important;
}

button {
    font-family: 'RobotoRegular' !important;
    letter-spacing: 0.5px !important;
}

ul {
    padding: 0 !important;
    list-style: none;
}

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    cursor: pointer;
}

::-webkit-scrollbar-track {
    background: #454871;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #dedede;
    border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.app-content::-webkit-scrollbar-track,
.app-content ::-webkit-scrollbar-track {
    /* background: #dedede; */
    background: transparent;
    border-radius: 10px;
}

.app-content::-webkit-scrollbar-thumb,
.app-content ::-webkit-scrollbar-thumb {
    background-color: #454871;
    border-radius: 10px;
}

/*********** Margin classes **************/

.mt-20 {
    margin-top: 20px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}


.mt-10 {
    margin-top: 10px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.padding-15 {
    padding: 15px !important;
}

.padding-10 {
    padding: 10px !important;
}

.padding-20 {
    padding: 20px !important;
}


/*********** Button classes **************/

.btn-primary-rounded,
.btn-white,
.btn-white-rounded {
    font-size: 14px;
    line-height: 17px;
    transition: all ease 0.2s;
}

.btn-primary-rounded {
    border-radius: 20px;
    background: #010433 !important;
    border: 0px;
    color: #fff !important;
    padding: 6.5px 25px;
    font-family: 'RobotoMedium';
}

.btn-primary-rounded:hover {
    box-shadow: 0px 3px 6px #0104338c;
}

.btn-white {
    color: #000000 !important;
    background: #fff !important;
    border: 1px solid #707070 !important;
    padding: 5.5px 12px;
    border-radius: 3px;
    font-family: 'RobotoRegular';
    box-shadow: none !important;

}

.btn-white:hover {
    color: #fff !important;
    background: #010433 !important;
    border-color: #010433 !important;
}

.btn-white-rounded {
    border-radius: 100px !important;
    padding: 8px 25px !important;
}

.btn-primary {
    /* margin: 0 !important; */
    background: #030635 !important;
    color: #fff !important;
    border: 0px !important;
    padding: 5px 20px !important;
    transition: all ease 0.2s;
    box-shadow: none !important;
    border-radius: 3px;
}

.btn-primary:hover {
    background: #4e5071 !important;
}

/*********** Color classes **************/

.green-text {
    color: #03DE73;
}

.red-text {
    color: #F52D56;
}

/*********** Background Color classes **************/

.salmon-bg {
    background: #FF8960 !important;
}

.green-bg {
    background: #4CAF50 !important;
}

.orange-bg {
    background: #e99629 !important;
}

.red-bg {
    background: #db460b !important;
}

.grey-bg {
    background: #c7c7c7 !important;
    color: #000 !important;
}

/*********** Font size classes **************/

.f-20 {
    font-size: 20px !important;
}

/*********** Form elements css **************/

.form-group {
    margin-bottom: 10px;
}

.form-group label,
.label {
    color: #656565;
    margin-bottom: 2px;
    position: relative;
}

.form-group.required label:after {
    content: '*';
    color: #f00;
    position: absolute;
    right: -10px;
    font-family: 'RobotoBold';
    top: -2px;
}

input:focus,
select:focus,
textarea:focus {
    box-shadow: none !important;
    border-color: #696969 !important;
}

textarea {
    resize: none;
    height: 92px;
}

.form-control {
    box-shadow: 0px 3px 6px #D6D5DF29;
    border: 1px solid #696969;
}

.form-group .form-control {
    border: 1px solid #ccc;
}

.visible-md {
    display: none;
}

.app-block {
    background: #fff;
    border-radius: 10px;
    /* box-shadow: 0 3px 10px 0 rgb(0 0 0 / 6%), 0 3px 10px 0 rgb(0 0 0 / 6%); */
    box-shadow: 1px 3px 16px #878787 !important;
    padding: 20px !important;
}

.common-shadow {
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 6%), 0 3px 10px 0 rgb(0 0 0 / 6%) !important;
}

.app-block-padding {
    padding: 55px 80px !important;
}

.app-block-small-form {
    padding: 40px 50px !important;
}

.app-block-form {
    padding: 4vh 4vw !important;
}

.mx {
    margin-left: 4vw;
    margin-right: 4vw;
}

.slide-panel .app-filters {
    margin-top: 14px;
}

.app-filters {
    /* margin-bottom: 10px; */
    margin-bottom: 6px;
}

.app-filters button {
    /* box-shadow: 0px 2px 20px #cfd9df66; */
    border: 1px solid #696969 !important;
    border-radius: 3px !important;
    background: #fff !important;
    padding: 6px 15px;
    margin-right: 10px;
    transition: all ease 0.2s;
    margin-bottom: 10px;
    color: #242E42 !important;
}

.app-filters button:not(:disabled):hover {
    color: #fff !important;
    background: #030635 !important;
    border-color: #030635 !important;
}

.app-filters button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.app-filters .form-control {
    width: auto;
    font-size: 14px;
    display: inline-block;

}

/* .app-filters select.form-control { */
/* box-shadow: 0px 2px 20px #CFD9DF66; */
/* } */

.app-dropdown {
    display: inline-block;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
}

/* .app-dropdown:after {
    width: 10px;
    height: 10px;
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 1;
    background: url('/assets/images/common/down-arrow-small.svg') no-repeat;
    margin-top: -3px;
} */


.rounded-border {
    border: 1px solid #e4e4e4;
    padding: 5px 8px;
    border-radius: 50px;
    margin-right: 20px;
}

.app-dropdown select.form-control {
    padding-right: 30px;
    background: #fff;
}

.greyText {
    color: grey;
}

.app-search {
    width: 150px;
    display: inline-block;
    margin-bottom: 10px;
}

.app-search-big {
    width: 250px;
}

.app-search .form-control {
    padding-left: 32px;
    background: #fff url('/assets/images/common/search.svg') 10px 9px no-repeat;
}

.customer-image-info h2 {
    font-size: 30px;
    color: #242E42;
    font-family: 'RobotoBold';
}

.customer-image-info p {
    color: #656565;
    font-size: 18px;
}

.form-check {
    min-height: inherit;
    margin-bottom: 0px;
}

.form-check label {
    cursor: pointer;
}

.form-check-input[type="checkbox"] {
    width: 14px;
    height: 14px;
    cursor: pointer;
    border-radius: 2px !important;
}

.form-check-input:checked[type="checkbox"] {
    background-color: #010433;
    border-color: #010433 !important;
}

.radio-group {
    margin: 5px 0 10px 0;
}

.radio-group .form-check {
    display: inline-block;
    margin-right: 40px;
    color: #656565;
}

.form-check-input[type="radio"] {
    border: 1px solid #656565;
}

.form-check-input:checked[type="radio"] {
    background-color: #fff;
    border-color: #FFC210 !important;
    position: relative;
    background-image: none;
}

.form-check-input:checked[type=radio]:before {
    content: '';
    width: 6px;
    height: 6px;
    background: #FFC210;
    top: 3px;
    left: 3px;
    position: absolute;
    border-radius: 100px;
}

.custom-tabs {
    border-bottom: 1px solid #E1E1F1;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    /* border: 2px solid red; */
}

.custom-tabs li {
    margin: 10px 10px 10px 0px;

}

.custom-tabs li a {
    border-bottom: 2px solid transparent;
    font-size: 18px;
    color: #656565 !important;
    /* border: 2px solid rgb(0, 38, 255); */
    padding: 5px;
    cursor: pointer;
    transition: all ease 0.2s;
}

.custom-tabs li.active a {
    color: #010433 !important;
    font-family: 'RobotoBold';
    border-bottom-color: #FFC210;
}

.info-title {
    font-size: 18px;
    color: #242E42;
}

.info-title sup {
    color: #f00;
}

.switch-input {
    width: 35px;
    height: 13px;
    position: relative;
    margin-right: 3%;
}

.switch-input input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch-input .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border: 2px solid #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 10px;
}

.switch-input .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: -2px;
    bottom: -4px;
    background-color: #fff;
    transition: .4s;
    border: 2px solid #ccc;
    border-radius: 100%;
}

.switch-input input:chec ked+.slider {
    background-color: #fff;
}

.switch-input input:checked+.slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
    left: 0px;
    border-color: #FFC210;
}

.btn-checkbox {
    box-shadow: 0px 2px 20px #cfd9df66 !important;
    border: 1px solid #EAEDEF;
    background: #fff !important;
    transition: all ease 0.2s;
    border-radius: 100px;
    padding: 6px 25px 6px 40px;
    display: inline-block !important;
    padding-left: 40px !important;
    cursor: pointer;
}

.btn-checkbox:hover {
    color: #fff !important;
    background: #030635 !important;
    border-color: #030635 !important;
}

.add-btn {
    padding: 8px 15px 8px 12px !important;
}

.add-btn em {
    margin-right: 5px;
}

.add-btn em svg {
    fill: #4caf50;
}

.add-btn:hover em svg {
    fill: #fff;
}

.bible .menu-icon .hamburger-icon em svg {
    cursor: pointer;
    fill: #000 !important;
    width: 20px;
    stroke: #000 !important;
}

.menu-icon .hamburger-icon em svg {
    cursor: pointer;
    fill: #fff;
    width: 20px;
    stroke: #fff;
}

.footer-tabs em svg {
    cursor: pointer;
    fill: #727070;
    width: 20px;
    stroke: #727070;
}

em svg {
    cursor: pointer;
    fill: #656565;
}

.music-em .em-icon svg {
    height: 14px;
    width: 14px;
}

h3,
h4 {
    color: #242E42;
    font-family: 'RobotoBold';
}

h3 {
    font-size: 20px;
}

h4 {
    font-size: 18px;
}

.form-control:disabled,
.form-control[readonly] {
    opacity: 0.5;
}

.app-select-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 10px 20px;
    box-shadow: 0px 2px 20px #00000040;
    min-height: 60px;
    position: relative;
    border-radius: 10px;
    margin-bottom: 10px;
}

.app-select-bar button {
    margin-bottom: 0px;
}

.app-select-bar .selected-count {
    position: absolute;
    left: 20px;
}

.app-select-bar .selected-count span {
    margin-left: 30px;
}



.p-dialog .p-dialog-header {
    border-bottom: 0 !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin: 10px;
}

p-calendar.monthDate .p-inputtext {
    border-color: #e0e0e0 !important;
    width: 115px;
    color: grey;
    border: 1px solid #696969;
    border-radius: 100px;
    background: #fff;
    padding: 6px 15px;
    margin-right: 10px;
    transition: all ease 0.2s;
    margin-bottom: 10px;
    color: #242E42;
    cursor: pointer;
}

p-dialog.tabModal .p-dialog-content {
    overflow-y: hidden !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    border: 1px solid #efefef;
    color: #969696;
}

.players-div .plyr {
    height: auto !important;
}

.plyr {
    height: 80px !important;
    min-width: 130px !important;
    border-radius: 5px;
}

.plyr--video .plyr__controls {
    padding: 0 !important;
}

.sub-player-div .plyr {
    height: 100% !important;
}

.footer-tabs ul li .icons svg {
    width: 15px !important;
    height: 15px !important;
}


.planschoose-input .form-group {
    width: 50%;
    margin: 0 1rem;
}

.planschoose-input .price-input {
    height: 35px;
    padding-left: 20px;
    padding-bottom: 7px;
    font-size: 16px;
}

.planschoose-input .detail {
    position: absolute;
    top: 30px !important;
    left: 6px !important;
    color: grey;
}


.pricing-container .onediv {
    width: 185px;
    height: 170px;
}

.pricing-container .planschoose {
    padding: 15px !important;
}

.artwork-container .artwork-detail {
    width: 100%;
    text-align: center;
}

:host ::ng-deep .p-dialog {
    border-radius: 15px;
}

:host ::ng-deep .p-dialog-content {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}

:host ::ng-deep .p-dialog .p-dialog-header {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}

:host ::ng-deep .p-dialog .p-dialog-footer {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
}

.recent-item-list li {
    position: relative;
}

.recent-item-list .route-to-width {
    width: 100%;
}

@media screen and (min-width:1200px) and (max-width: 1400px) {
    .pricing-container .onediv {
        width: 160px !important;
        height: 150px !important;
    }

    .overlay-overflow {
        overflow: overlay !important;
    }
}

@media screen and (max-width: 560px) {
    .footer-tabs ul li .icons svg {
        width: 20px !important;
        height: 20px !important;
        margin-bottom: 5px;
    }

    .app-block-padding {
        padding: 30px 30px !important;
    }
}


@media screen and (min-width:310px) and (max-width: 374px) {
    .footer-tabs ul li .icons svg {
        width: 18px !important;
        height: 18px !important;
        margin-bottom: 5px;
    }
}

/* .footer-tabs .tabs .icons svg, svg path, svg line, svg circle, svg rect {
    stroke: #4b4b4b;
} */

@media screen and (min-width:1810px) and (max-width: 2000px) {


    .artwork-container .artwork-img.square {
        width: 8vw !important;
        margin-bottom: 1.5vh !important;
    }

    .artwork-container .artwork-img.wide {
        width: 16vw !important;
    }

    .artwork-img.banner {
        width: 16vw !important;
        margin-bottom: 3.6vh !important;
    }
}

@media screen and (max-width: 439px) {
    .switch-input {
        width: 65px !important;
    }
}

@media only screen and (min-width:1040px) and (max-width:1200px) {
    .planschoose .onediv {
        width: 130px !important;
        height: 115px !important;
    }

    .overlay-overflow {
        overflow: overlay !important;
    }

    .planschoose .onediv i {
        font-size: 45px !important;
        margin-bottom: 5px !important;
    }

    .planschoose .onediv p {
        width: 130px !important;
        font-size: 10px !important;
    }

    .info-block .info-content-card {
        font-size: 10px !important;
    }

    .file-info p {
        width: 350px !important;
    }
}



.app-dropdown select {
    /* styling */
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;

    /* reset */

    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
}


.app-dropdown select {
    background-image:
        linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc) !important;
    background-position:
        calc(100% - 15px) calc(12px + 2px),
        calc(100% - 10px) calc(12px + 2px),
        calc(100% - 2.5em) 0.5em !important;
    background-size:
        5px 5px,
        5px 5px,
        1px 0em !important;
    background-repeat: no-repeat !important;
}

.app-dropdown select:focus {
    /* background-image:
      linear-gradient(45deg, green 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, green 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position:
      calc(100% - 15px) 1em,
      calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em; */
    background-size:
        5px 5px,
        5px 5px,
        1px 0em;
    background-repeat: no-repeat;
    /* border-color: green; */
    outline: 0;
}


.app-dropdown select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

app-video-player.radius1 .video-js,
app-video-player.radius1 .vjs-tech {
    border-radius: 8px !important;
}

app-video-player.radius1 .video-js .vjs-modal-dialog {
    border-radius: 12px !important;
}

.delete-text {
    width: 171px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    margin-bottom: -5px;
}

.delete-text-normal {
    display: inline-block;
    margin-bottom: -5px;
}
